import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { generalConfig } from '../../@core/constants/general-config.constant';
import { ChangePasswordService } from '../../change-password/service/change-password.service';
import { NbToastrService } from '@nebular/theme';
import { WebStorage } from '../../@core/web.storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password-dialog',
  templateUrl: './reset-password-dialog.component.html',
  styleUrls: ['./reset-password-dialog.component.scss'],

})
export class ResetPasswordDialogComponent implements OnInit {
  dialogData: any;
  changePasswordForm: FormGroup;
  loading: boolean;
  submitted: boolean;
  patternMessage = generalConfig.patternMessages;
  pattern = generalConfig.pattern;

  constructor(
    private formBuilder: FormBuilder,
    private changePasswordService: ChangePasswordService,
    private toastrService: NbToastrService,
    private _storage: WebStorage,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResetPasswordDialogComponent>) {

    this.dialogData = data;
  }

  ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(generalConfig.pattern.PASSWORDMINLENGTH), Validators.maxLength(generalConfig.pattern.PASSWORDMAXLENGTH), Validators.pattern(generalConfig.pattern.STRONGPASSWORD)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(generalConfig.pattern.PASSWORDMINLENGTH), Validators.maxLength(generalConfig.pattern.PASSWORDMAXLENGTH), Validators.pattern(generalConfig.pattern.STRONGPASSWORD)]],
    })
  }

  cancel(): void {
    this.dialogRef.close();
  }

  get f() { return this.changePasswordForm.controls; }

  showToast(position, status, message) {
    this.toastrService.show(null, message, { position, status });
  }

  resetPassword() {

    this.submitted = true;
    this.loading = true;
    let resetPasswordObj = {};
    if (this.changePasswordForm.valid) {
      if (this.changePasswordForm.value.password == this.changePasswordForm.value.confirmPassword) {
        if (this.dialogData.hasOwnProperty("companyUser")) {
          resetPasswordObj = {
            userId: this.dialogData.companyUser._id,
            password: this.changePasswordForm.value.password,
            confirmPassword: this.changePasswordForm.value.confirmPassword
          };
        } else {
          resetPasswordObj = {
            userId: this.dialogData.userID,
            password: this.changePasswordForm.value.password,
            confirmPassword: this.changePasswordForm.value.confirmPassword
          }
        }



        this.changePasswordService.resetCompanyPassword(resetPasswordObj).subscribe(res => {

          this.loading = false;
          this.changePasswordForm.reset();

          if (res.code == generalConfig.statusCode.ok) {
            if (this.dialogData.hasOwnProperty("companyUser")) {
              this.showToast(generalConfig.toastrPosition.topRight, generalConfig.toastrStatus.success, res.message);
              this.cancel();
            } else {
              this.showToast(generalConfig.toastrPosition.topRight, generalConfig.toastrStatus.success, res.message);
              this.cancel();
              localStorage.clear();
              this._storage.clearAll();
              this.showToast(generalConfig.toastrPosition.topRight, generalConfig.toastrStatus.success, generalConfig.messages.LogoutSuccessfully);
              setTimeout(() => this.router.navigate(['/']), 3000);
            }

          } else {
            this.showToast(generalConfig.toastrPosition.topRight, generalConfig.toastrStatus.danger, res.message);
          }
        })
      }
      else {
        this.changePasswordForm.controls['password'].setValue(null);
        this.changePasswordForm.controls['confirmPassword'].setValue(null);

        this.loading = false;
        this.showToast(generalConfig.toastrPosition.topRight, generalConfig.toastrStatus.danger, generalConfig.messages.PasswordMismatch);

        Object.keys(this.changePasswordForm.controls).forEach((item) => {
          if (item == 'confirmPassword' || item == 'password') {
            this.changePasswordForm.controls[item].markAsDirty()
          }
        })
      }
    } else {
      this.loading = false;
      this.changePasswordForm.reset();
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiUrlConstant } from '../../../@core/constants/api-url.constant';
import * as Rx from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(
    private http: HttpClient
  ) { }

  editProfile(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'editProfile', data);
  }

  editProfileImage(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'editProfileImage', data);
  }

  getAdminDetails(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'getAdminDetails', data);
  }

  addCardDetails(data: any): Observable<any> {
    return this.http.post(environment.apiUrl + 'card/add', data);
  }

  listCards(planId: string): Rx.Observable<any> {
    const apiUrl=ApiUrlConstant.LISTCARDS
    const url = `${apiUrl}?limit=${planId}`;
    return this.http.post(url,{})
  }

  deleteCards(planId: string): Rx.Observable<any> {
    const apiUrl=ApiUrlConstant.DELETECARDS
    const url = `${apiUrl}?cardId=${planId}`;
    return this.http.post(url,{})
  }
  
}

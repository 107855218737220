import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
} from './components';

import {
  OneColumnLayoutComponent,
} from './layouts';
import { ExpNebulaModule } from '../nebula.module';
import { ExpMaterialModule } from '../material.module';
import { MarketingPagesHeaderComponent } from './components/marketing-pages-header/marketing-pages-header.component';
import { MarketingPagesFooterComponent } from './components/marketing-pages-footer/marketing-pages-footer.component';
import { PagesColumnLayoutComponent } from './layouts/one-column/pages-column.layout';
const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  OneColumnLayoutComponent,
  PagesColumnLayoutComponent
];

@NgModule({
  declarations: [...COMPONENTS,MarketingPagesHeaderComponent,MarketingPagesFooterComponent,], //ResetPasswordDialogComponent
  imports: [CommonModule, ...NB_MODULES, ExpNebulaModule, ExpMaterialModule, ReactiveFormsModule, FormsModule],
  // entryComponents: [ResetPasswordDialogComponent],
  exports: [CommonModule, ...COMPONENTS],


})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          // [DEFAULT_THEME],
        ).providers,
      ],
    };
  }
}


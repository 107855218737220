import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-marketing-pages-header',
  templateUrl: './marketing-pages-header.component.html',
  styleUrls: ['./marketing-pages-header.component.scss']
})
export class MarketingPagesHeaderComponent implements OnInit {

  constructor(
    private router: Router,

  ) { }

  ngOnInit() {
  }
  isCollapsed: boolean = false; toggleCollapse(targetId: string) { this.isCollapsed = !this.isCollapsed; 
  }
  redirectToLogin() {
    this.router.navigate(['/login']);
  }
  redirectToSignup() {
    this.router.navigate(['/sign-up']);
  }
  redirectToAbout(){
    this.router.navigate(['/about']);
  }
  redirectToFeature(){
    this.router.navigate(['/features']);
  }
  redirectToContact(){
    this.router.navigate(['/contact']);
  }
  redirectToHome(){
    this.router.navigate(['/']);
  }
}

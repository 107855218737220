import { TreeNode } from 'angular-tree-component';

export const generalConfig = {
    userType: {
        'SUPERADMIN': 'Super Admin',
        'COMPANYADMIN': 'Company Admin',
        'COMPANYSAFETYADMIN': 'Company Safety Admin',
        'EMPLOYEE': 'Employee',
        'SUBADMIN': 'subAdmin'
    },
    storage: {
        'USER': 'user',
        'USERNAME': 'username',
        'USERTYPE': 'usertype',
        'PROFILE_PIC': 'profilepic',
        'TOKEN': 'token',
        "MODULE": 'modulename',
        'ID': 'id',
        'CID': 'companyId',
        'SUPERADMINID': 'superAdminId',
        'COMPANYNAME': 'companyName',
        'INDUSTRYTYPEID': 'industryTypeId',
        'COMPANYTYPEID': 'companyTypeId',
        'USERFNAME': 'userfname',
        'USERLNAME': 'userlname',
        'All': 'all',
        'image': 'image',
        'document': "__doc",
        'pdfImageBase64': "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHsAAAB7CAMAAABjGQ9NAAAAaVBMVEX/////2dnLWFjXhobHQkLclpbmtrbAFxftzMzUenrPXl74ysrGPDzGPj7/4ODAHx/FNzf03Nz1v7/EMjL9+PjJSUnrwcH88/P46ensxsbSb2/x09PSaWnLTk7ipKTYi4vmrKzyt7fCJyfB4yToAAADaklEQVRoge3b4W6bMBAAYAoHC85dZw+ICWDC+v4PuSPdNE1NKmPfZE3zqVLzp/3is+9srKQocuT4/2ItA0OpJtau9a0KiZtDjMVrCPzDdlh0JB5uq3NJl1T261l1USOPsV+/qai0x9gvL4xHpD3OfjmriNUeafOch6c90t7T3oWmPdpmPLTU4u3XcxmY9nj7JbjUBGxOe1iHE7EDO5yIHZh2GXtf7Xg47VJ2SIcTswM6nJgd0OEE7cMdTtI+2uEk7XupHRi5qH2wwwnbhzqcsH2ow0nbO+7b4cLt8vwkvjtPPNx++/ok3jrPKQ+2zel5vP1l+7P4ku1sZ/uYbWsH9ZjE7qcOQLc2hb1p0PzjvTML2nZC3BqNUwLbKCxH08GQwJ4dLqPtgFLYJU677V2Osjlf9py7BPa48HyfOlIJ7KJFMJvGKoVdY3etUG8p7AtiXUI3p7CtBgcA3g1ddC9RBEBTkn6+N3TQqy8ta4/IKb+msW2LpEwau+BdrOrT2H2FtKQ5txR2ADhwhSW+zmlJU2Nc3wP432BJ2gZBNzrNPlYjTn2L3pcZovs34aWYB/Rd6oJ2A6TGor8hebZVOZubGtbFfmSkwa+3ydkzgjvtL1ZNzqu5ydncy6c72Svy28zE7LGDX/dGVw2Dz24mZlf4+3C8EjmPk5OUbRz8rmvbEqp5Pl33OM3mSZeVsmvcC2yP3ly2ifcU5wZCIgKn2vphnxWyzQDUFNZsben4JZ/b7oFIiBoRYXlQdkJ2xUfUuqVO86mJUHOV7fRwW9e1vrWK34H7iMvYM94Hya7udFmtzXVueOzDep9pay4T6Y+PKxL2pXXvCebUrqfRvjeWCxI32Z//nh9PS3l73KC7J1q16591ZSYE6ob6ZIy54YPriEh7XEt9H/PUfJzPcd2nAfmtaYQHO2ucfVn0+5J+7+QfYm559XGdEXYPrp9ibFtpria18Nie9u9xnZQq1fSIibDHVgOq7VRyxj/bt+xoHh8mwm3LHVy34/4L/Z9FZOwGYWj2YwpA4Ac4gm2riDamNfgekeRs3q+dNdxLu5v/E5iQbbmuF0c85aF0uN3zkIm020LlmLU23kC7yvtiR9Queq7b4HxH2tGR7WxnO9vZzna2s53tbMfagd8n+jQ8P78W/D2qTyPuuz45cvzL8QN/ST8UCicgwAAAAABJRU5ErkJggg==",
        'profileImageBase64': "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMAAAADACAAAAAB3tzPbAAACOUlEQVR4Ae3aCQrrIBRG4e5/Tz+CBAlIkIAECUjoSt48z/GZeAvnrMCvc6/38XzxAAAAYC4AAAAAAAAAAAAAAAAAAAAAAAAAAAAMCAAAAAAAAAAAAAAAAPsagz4V4rq/FmCLTj/k4vYqgCN5/TKfjlcAJKff5pJ5QPH6Y77YBiz6a4thQJ30D03VKmB3+qfcbhOwO+l+waP/+VsEBgDV6USumgNMOtVkDbDoZIstQNHpiimA1+m8JUBSQ8kO4HBqyB1mAElNJTMAr6a8FcCmxjYjgKjGohGAU2POBmBXc7sJwKrmVhOAqOaiCUBQc8EEQO0JwPMB4ADASwhAe3yR8VPiP3/M8XOaPzQd/lLyp56xSuvnUGK0yHC313idCw6umNov+bhm5aK7fdWAZQ/WbdoXnlg5Y+mvfe2SxVdWj20FAAAAAAAAAAAAwFQAAJSS0hwmfVMIc0qlmAfsOQWvP+RDyrtNQM1L0D8WllxNAWqOXifzMVcbgG3xaswv22jAFp3a6zFteYw8fQ9DM6Amr275VG8GlFmdm8uNgDzpgqZ8EyB7XZTPNwDKpAubysWAOuvi5nolYHW6PLdeBjiCbikc1wCK0025cgUg68Zyf0DUrcXegKibi30Bq25v7QnYNKCtH+BwGpA7ugFmDWnuBSgaVOkECBpU6AOoGlbtAlg1rLULIGhYoQvAaViuC0AD6wE4Xh1QAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADA194CuqC6onikxXwAAAAASUVORK5CYII=",
    },
    pattern: {
        'ALPHANUMERICANDSPECIALCHAR':/[a-zA-Z0-9_ ]*/,
        'NAME': /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)$/,
        'ADDRESS': /^[a-zA-Z0-9\s,'-]*$/,
        'ALPHANUMERIC': /^[a-zA-Z0-9_ ]*$/,
        'NUMERIC': /^[0-9]*$/,
        "CITY": /^([a-zA-Z\u0080-\u024F]+(?:. |-| |'))*[a-zA-Z\u0080-\u024F]*$/,
        "EMAIL": /^(([^<>()\[\]\\.,,:\s@"]+(\.[^<>()\[\]\\.,,:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "POSTAL_CODE": /^[0-9]{5}-[0-9]{4}$|^[0-9]{5}$|^[A-Z][0-9][A-Z][0-9] [A-Z][0-9][A-Z][0-9]$/,
        "PHONE_NO": /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,4}$/,
        "MOB_NO": /^\d{3}[-\s]?\d{3}[-\s]?\d{4}$/,
        "PASSWORD": /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{6,}/,
        "STRONGPASSWORD": /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}/,
        "SUB_DOMAIN": /^[/a-z/A-Z][a-zA-Z0-9-]*[^/-/./0-9]$/,
        "PHONE_NO_MASK": ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
        "USERNAME": /^[a-zA-Z0-9](_(?!(\.|_))|\.(?!(_|\.))|[a-zA-Z0-9]){1,14}[a-zA-Z0-9]$/,
        "MAXLENGTH": 50,
        "MINLENGTH": 3,
        "PASSWORDMAXLENGTH": 13,
        "PASSWORDMINLENGTH": 8,
        "MOBILEMAX": 10,
        "MOBILEMIN": 10,
        "NAMEMAXLENGTH": 16,
        "NAMEMINLENGTH": 3,
        "CATMAXLENGTH": 50,
        "CATMINLENGTH": 3,
        "ADDRESSMAXLENGTH": 100,
        "COMPANYMAXLENGTH": 50,
        "TOTALEMPLOYEESLENGTH": 9,
        "LENGTH":17,
        "CURRENCY":/^(?![0,.]+$)(?:0|[1-9]\d{0,2}(?:,\d{3})*|[1-9]\d*)(?:\.\d{1,2})?$/
    },

    patternMessages: {
        minLength: "Minimum required length is ",
        maxLength: "Maximum required length is ",
        numeric: "Please enter numeric values only.",
        alphabets: "Please enter alphabets only.",
        alphNumeric: "Please enter alph numeric values only.",
        noSpecialCharacter: "Special characters not allowed.",
        strongPassword: "Passsword must be combination of Number, Capital, Small, Special character.",
        validEmail: "Please enter valid email address.",
    },

    paginator: {
        COUNT: 8,
        PAGE: 1,
        pageNumbers: "5, 10, 25, 100"
    },

    statusCode: {
        "ok": 200,
        "unauthorized": 401,
        "notFound": 404,
        "badRequest": 400,
        "internalError": 500,
        "forbidden": 403,
        "notSupported": 402,
        "conflict": 409,
        "accepted": 202,
        "serviceUnavailable": 503
    },
    passwordCreatedmessage: 'Password created sucessfully',
    options: {
        displayField: 'name',
        isExpandedField: 'expanded',
        idField: null,
        hasChildrenField: 'nodes',
        actionMapping: {},
        nodeHeight: 16,
        nodeClass: (node: TreeNode) => {
            return 'icon-' + node.data.icon;
        },
        allowDrag: (node) => {
            return true;
        },
        allowDrop: (node) => {
            return true;
        },
        allowDragoverStyling: true,
        levelPadding: 10,
        useVirtualScroll: false,
        animateExpand: true,
        scrollOnActivate: false,
        animateSpeed: 30,
        animateAcceleration: 1.2
    },

    toastrStatus: {
        basic: 'basic',
        primary: 'primary',
        success: 'success',
        info: 'info',
        warning: 'warning',
        danger: 'danger',
        control: 'control'
    },

    toastrPosition: {
        topRight: 'top-right',
        bottomLeft: 'bottom-left',
        bottomRight: 'bottom-right',
        topLeft: 'top-left',
        topEnd: 'top-end',
        bottomEnd: 'bottom-end',
        topStart: 'top-start',
        bottomStart: 'bottom-start',
        bottomCenter:'top-center' 
    },

    route: {
        dashboard: 'dashboard',
        hasp: 'hasp',
        equipment: 'equipment',
        chemical: 'chemical',
        cert: 'cert',
        training: 'training',
        tools: 'tools',
    },

    assetModule: {
        category: 'CATEGORY',
        hasp: 'HASP',
        equipment: 'EQUIPMENT',
        chemical: 'CHEMICAL',
        cert: 'CERT',
        training: 'TRAINING',
        tools: 'TOOLS',

    },

    assetTitleModule: {
        category: 'Category Management',
        hasp: 'HASP',
        equipment: 'Equipment',
        chemical: 'Chemical',
        cert: 'CERT',
        training: 'Training',
        tools: 'Tools',

    },

    messages: {
        dropNotAllowed: 'Invalid action. Cannot drop into file.',
        uploadImageMessage: "Please upload image.",
        invalidEmail: 'Invalid Email!!',
        PasswordNotMatched: 'Passwords did not matched!!',
        PasswordMismatch: "Password and confirm password do not match.",
        LogoutSuccessfully: "Logout Successfully!! As password has been changed.",
        Logout: "Logout Successfully!!.",
        invalidToken: "Invalid token!",
        sessionExpired: "Session Expired!",
        PageNotFoundTitle: "404 Not Found",
        PageNotFoundSubTitle: "The page you were looking for does not exist.",
        ForbiddenTitle: "403 Forbidden",
        ForbiddenSubTitle: "You do not have sufficient permissions to access this resource.",
        noFileSelected: "No files selected to upload.",
        fileTypeNotSupported: "One of the file(s) has unsupported file format.",
        downloadMessage: "Kindly download file from new window."
    },

    modes: {
        ADD: 'ADD',
        VIEW_FILE: 'VIEW FILE',
        EDIT: 'EDIT',
        VIEW: 'VIEW',
        DELETE: 'DELETE',
        ASSIGN: 'ASSIGN',
    },

    pdfMargins: {
        top: 10,
        bottom: 10,
        left: 10,
        right: 10,
        width: 190

        // top: 40,
        // bottom: 40,
        // left: 40,
        // width: 750
    },

    downloadAssetUrl: {
        hasp: 'hasp/',
        equipment: 'equipment/',
        chemical: 'chemical/',
        cert: 'cert/',
        training: 'training/',
        tools: 'tools/'
    },
    downloadAssetDocUrl: {
        hasp: 'hasp/documents/',
        equipment: 'equipment/documents/',
        chemical: 'chemical/documents/',
        cert: 'cert/documents/',
        training: 'training/documents/',
        tools: 'tools/documents/'

    },

    spinner: {
        size: 'giant',
        status: 'danger'
    },

    user: {
        client: '/pages/',
        company: '/company/',
        safetyAdmin: '/safetyAdmin/',
        employee: '/employee/'
    },
    rolesPermissions: {
        client: '/pagesAccess/',
        company: '/companyAccess/',
        safetyAdmin: '/safetyAdminAccess/',
        employee: '/employeeAccess/'
    },
    URL: {
        forgetPassword: '/forget-password',
        changePassword: '/change-password'
    },

}
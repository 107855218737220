import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbAlertModule,
  NbTooltipModule,
  NbButtonModule,
  NbIconModule,
  NbSelectModule,
  NbInputModule,
  NbCardModule,
  NbActionsModule,
  NbUserModule,
  NbCheckboxModule,
  NbRadioModule,
  NbAccordionModule,
  NbSpinnerModule
} from '@nebular/theme';

@NgModule({
  exports: [
    NbChatModule,
    NbDatepickerModule,
    NbDialogModule,
    NbMenuModule,
    NbSidebarModule,
    NbToastrModule,
    NbWindowModule,
    NbAlertModule,
    NbTooltipModule,
    NbButtonModule,
    NbInputModule,
    NbCardModule,
    NbActionsModule,
    NbUserModule,
    NbAccordionModule,
    NbCheckboxModule,
    NbRadioModule,
    NbSelectModule,
    NbIconModule,
    NbSpinnerModule
  ],
  schemas: [

  ],
})
export class ExpNebulaModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ExpNebulaModule
    };
  }

}

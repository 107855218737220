import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MainService {

  /* Update logged in user header information */
  private imgURLSubject = new BehaviorSubject('')
  private firstNameURLSubject = new BehaviorSubject('')

  imgURLObservable = this.imgURLSubject.asObservable();
  firstNameURLObservable = this.firstNameURLSubject.asObservable();

  /* send data from document component to document editor component on add/edit document. */
  private document = new BehaviorSubject('');
  documentObservable = this.document.asObservable().pipe(distinctUntilChanged());

  /* send data from company list component to employee list component on manage employees. */
  private companyDataSubject = new BehaviorSubject('');
  companyDataObservable = this.companyDataSubject.asObservable();

  constructor() { }

  changeProfileImage(img) {
    this.imgURLSubject.next(img)
  }

  changeProfileFirstName(firstName) {
    this.firstNameURLSubject.next(firstName)
  }

  setCategoryDocument(document) {
    this.document.next(document);
  }

  setCompanyData(companyData) {
    this.companyDataSubject.next(companyData);
  }

  ngOnInit(): void {

    this.companyDataSubject.unsubscribe();
  }

 
}
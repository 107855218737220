import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiUrlConstant } from '../../@core/constants/api-url.constant';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordService {

  constructor(
    private http: HttpClient
  ) { }
  changePassword(data: any): Observable<any> {
    return this.http.post(ApiUrlConstant.changePassword, data);
  }

  resetCompanyPassword(data: any): Observable<any> {
    return this.http.post(ApiUrlConstant.RESETCOMPNAYPASSWORD, data);
  }

  //for creating password
  checkUrl(data: any): Observable<any> {

    return this.http.post(ApiUrlConstant.CHECKURL, data);
  }
}

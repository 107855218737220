import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Contacts, RecentUsers, UserData } from '../data/users';
import { WebStorage } from '../../@core/web.storage';
import { generalConfig } from '../constants/general-config.constant';




@Injectable()
export class UserService extends UserData {



  private _storage: WebStorage;
  public get storage(): WebStorage {
    return this._storage;
  }
  public set storage(value: WebStorage) {
    this._storage = value;
  }


  private time: Date = new Date;

  private users = {
    superAdmin: { name: 'Super Admin', picture: 'assets/images/nick.png' }
  };
  private types = {
    mobile: 'mobile',
    home: 'home',
    work: 'work',
  };
  private contacts: Contacts[] = [
    { user: this.users.superAdmin, type: this.types.home }
  ];

  getUsers(): Observable<any> {
    //this._storage.get(generalConfig.storage.ID);
    return observableOf(this.users);
  }

  getContacts(): Observable<Contacts[]> {
    return observableOf(this.contacts);
  }

  getRecentUsers(): Observable<RecentUsers[]> {
    return null;
  }
}

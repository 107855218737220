import { environment } from "../../../environments/environment";
export class ApiUrlConstant {

    private static appurl = environment.apiUrl;

    public static get LOGIN(): string { return this.appurl + 'login' }
    public static get LOGINASCOMPANY(): string { return this.appurl + 'loginAsCompany' }
    public static get LOGINASSUPERADMIN(): string { return this.appurl + 'loginAsSuperAdmin' }
    public static get forgetPassword(): string { return this.appurl + 'forgetPassword' }
    public static get changePassword(): string { return this.appurl + 'changePassword' }
    public static get RESETCOMPNAYPASSWORD(): string { return this.appurl + 'resetPassword' }
    //create password
    public static get CHECKURL(): string { return this.appurl + 'checkUrl' }

    /* GENERAL CATEGORY */
    public static get DOCLIST(): string { return this.appurl + 'folder/list' };
    public static get ADDFOLDER(): string { return this.appurl + 'folder/add' };
    public static get MOVEFOLDER(): string { return this.appurl + 'folder/edit' };
    public static get DELFOLDER(): string { return this.appurl + 'folder/delete' };
    public static get ASSETDOCLIST(): string { return this.appurl + 'folder/listByAsset' };
    public static get PUBLISHCATEGORY(): string { return this.appurl + 'folder/publish' };
    public static get ORDERFOLDER(): string { return this.appurl + 'folder/order' };

    /* EQUIPMENT */
    public static get EQP_DOCLIST(): string { return this.appurl + 'eqp_folder/list' };
    public static get EQP_ADDFOLDER(): string { return this.appurl + 'eqp_folder/add' };
    public static get EQP_MOVEFOLDER(): string { return this.appurl + 'eqp_folder/edit' };
    public static get EQP_DELFOLDER(): string { return this.appurl + 'eqp_folder/delete' };
    public static get EQP_ASSETDOCLIST(): string { return this.appurl + 'eqp_folder/listByAssetEqp' };
    public static get EQP_PUBLISHCATEGORY(): string { return this.appurl + 'eqp_folder/publish' };
    public static get EQP_ORDERFOLDER(): string { return this.appurl + 'eqp_folder/order' };

    /* CHEMICAL */
    public static get CHEM_DOCLIST(): string { return this.appurl + 'chem_folder/list' };
    public static get TRAIN_DOCLIST(): string { return this.appurl + 'train_folder/list' };
    public static get CHEM_ADDFOLDER(): string { return this.appurl + 'chem_folder/add' };
    public static get CHEM_MOVEFOLDER(): string { return this.appurl + 'chem_folder/edit' };
    public static get TOOL_MOVEFOLDER(): string { return this.appurl + 'tool_folder/edit' };
    public static get CHEM_DELFOLDER(): string { return this.appurl + 'chem_folder/delete' };
    public static get CHEM_ASSETDOCLIST(): string { return this.appurl + 'chem_folder/listByAssetChem' };
    public static get CHEM_PUBLISHCATEGORY(): string { return this.appurl + 'chem_folder/publish' };
    public static get CHEM_ORDERFOLDER(): string { return this.appurl + 'chem_folder/order' };

    /* HASP MODULE */
    public static get ADDSUBADMIN(): string { return this.appurl + 'add/subadmin' };
    public static get DOCBYCATEGORY(): string { return this.appurl + 'category/docs' };

    public static get POLICYDOCSCMP(): string { return this.appurl + 'folder/policy' };

    public static get HASPDOCUMENTBYID(): string { return this.appurl + 'document/get' };
    public static get HASPPDFBYID(): string { return this.appurl + 'hasp/getPDF' };

    public static get ADDEDITHASPDOCUMENT(): string { return this.appurl + 'hasp/add' };
    public static get DELETEDOCUMENT(): string { return this.appurl + 'hasp/delete' };
    public static get RESOREDOC(): string { return this.appurl + 'hasp/restore' };
    public static get UNLINKDOCUMENT(): string { return this.appurl + 'hasp/unlinkDocument' };

    public static get UPLOADHASPFILESPATH(): string { return this.appurl + 'document/uploadPath' };

    public static get UPLOADHASPFILES(): string { return this.appurl + 'document/upload' };
    public static get DOWNLOADHASPFILE(): string { return this.appurl + 'hasp/download' };
    public static get GETCOMPANYASSIGNEDCATEGORIES(): string { return this.appurl + 'folder/getAssignedCategories' };
    public static get DOCUMENTUPLOAD(): string { return this.appurl + 'hasp/uploadCallback' };

    /* EQUIPMENT MODULE */

    public static get GETEQUIPMENTDOCUMENT(): string { return this.appurl + 'equipment/getequipment' };
    public static get GETTOOLSDOCUMENT(): string { return this.appurl + 'tools/gettools' };
    public static get GETTRAININGSDOCUMENT(): string { return this.appurl + 'tainings/gettainings' };
    public static get ASSETDOCUMENTBYID(): string { return this.appurl + 'equipment/get' };
    public static get ASSETTOOLSDOCUMENTBYID(): string { return this.appurl + 'tools/get' };
    public static get ASSETTRAININGSDOCUMENTBYID(): string { return this.appurl + 'trainings/get' };
    public static get ADDEDITASSET(): string { return this.appurl + 'equipment/edit' };

    public static get ADDEDITASSETTOOLS(): string { return this.appurl + 'tools/edit' };
    public static get ADDEDITASSETTRAININGS(): string { return this.appurl + 'trainings/edit' };

    public static get DELETEFILEDOCUMENTASSET(): string { return this.appurl + 'equipment/delete' };
    public static get UPLOADMULTIPLEDOCUMENTS(): string { return this.appurl + 'equipment/upload' };


    public static get UPLOADMULTIPLEDOCUMENTSTOOLS(): string { return this.appurl + 'tools/upload' };

    public static get UPLOADMULTIPLEDOCUMENTSTRAINING(): string { return this.appurl + 'trainings/upload' };


    /* CHEMICAL MODULE */
    public static get CHEM_ASSETDOCUMENTBYID(): string { return this.appurl + 'chemical/get' };
    public static get CHEM_ADDEDITASSET(): string { return this.appurl + 'chemical/edit' };
    public static get CHEM_DELETEFILEDOCUMENTASSET(): string { return this.appurl + 'chemical/delete' };
    public static get CHEM_UPLOADMULTIPLEDOCUMENTS(): string { return this.appurl + 'chemical/upload' };

    /* COMPANY */
    public static get HASPDOCS(): string { return this.appurl + 'folders/hasp' };

    public static get EXPIRINGCERTS(): string { return this.appurl + 'company/expiryCerts' };
    public static get ASSIGNCERTS(): string { return this.appurl + 'company/assignCerts' };
    public static get EXPIREDCERTS(): string { return this.appurl + 'company/expiredCerts' };
    public static get HIDEWIDGET(): string { return this.appurl + 'company/hideWidget' };
    public static get GETWIDGETS(): string { return this.appurl + 'all/widgets' };
    public static get ADDWIDGETS(): string { return this.appurl + 'company/addWidget' };
    public static get DELETEPOLICYDOCS(): string { return this.appurl + 'company/DelePolicyDocs' };
    public static get COMPANY_POLICYINFO(): string { return this.appurl + 'company/ReplacePolicy' };
    public static get POLICYDOCS(): string { return this.appurl + 'company/policyDocs' };
    public static get LISTCOMPANY(): string { return this.appurl + 'company/list' };
    public static get ADDCOMPANY(): string { return this.appurl + 'company/add' };
    public static get EDITCOMPANY(): string { return this.appurl + 'company/edit' };
    public static get GETCOMPANYBYID(): string { return this.appurl + 'company/get' };
    public static get GETCOMPANYADMINBYID(): string { return this.appurl + 'company/getCompanyAdminById' };

    public static get TOGGLECOMPANYSTATUS(): string { return this.appurl + 'company/toggleStatus' };
    public static get DELETECOMPANY(): string { return this.appurl + 'company/delete' };

    public static get ADDINDUSTRYTYPE(): string { return this.appurl + 'company/addIndustryType' };
    public static get ADDCOMPANYTYPE(): string { return this.appurl + 'company/addCompanyType' };
    public static get GETINDUSTRY(): string { return this.appurl + 'company/getindustryTypes' };
    public static get GETCOMPANYTYPES(): string { return this.appurl + 'company/getCompanyTypes' };
    /* EMPLOYEE */
    public static get LISTEMPLOYEE(): string { return this.appurl + 'employee/list' };
    public static get ADDEMPLOYEE(): string { return this.appurl + 'employee/add' };
    public static get EDITEMPLOYEE(): string { return this.appurl + 'employee/edit' };
    public static get GETEMPLOYEEBYID(): string { return this.appurl + 'employee/get' };
    public static get sendEmail(): string { return this.appurl + 'employee/sendMail' };
    public static get TOGGLEEMPLOYEESTATUS(): string { return this.appurl + 'employee/toggleStatus' };
    public static get ASSIGNCERTSEMPLOYEE(): string { return this.appurl + 'company/assignCertsToUser' };
/* SUBSCRIPTION*/
public static get LISTPLANS(): string { return this.appurl + 'plan/list' };
public static get ADDPLANS(): string { return this.appurl + 'plan/add' };
public static get DELETEPLANS(): string { return this.appurl + 'plan/remove' };
public static get LISTCARDS(): string { return this.appurl + 'card/list' };
public static get DELETECARDS(): string { return this.appurl + 'card/delete' };
public static get SUBSCRIPTIONDETAILS(): string { return this.appurl + 'plan/specific/list' };






    public static get JOBTITLES(): string { return this.appurl + 'employee/jobTitles' };
    public static get ADDJOBTITLE(): string { return this.appurl + 'employee/addJobTitle' };

    public static get RENEWEMPLOYEECERTIFICATES(): string { return this.appurl + 'company/renewexpiredCerts' };
    public static get ASSIGNEMPLOYEECERTIFICATES(): string { return this.appurl + 'company/assignCertsToUser' };

    /* Company SafetyAdmin */
    public static get LISTSAFETYADMIN(): string { return this.appurl + 'safety_admin/list' };
    public static get ADDSAFETYADMIN(): string { return this.appurl + 'safety_admin/add' };
    public static get EDITSAFETYADMIN(): string { return this.appurl + 'safety_admin/edit' };
    public static get GETSAFETYADMINBYID(): string { return this.appurl + 'safety_admin/get' };
    public static get TOGGLESAFETYADMINSTATUS(): string { return this.appurl + 'safety_admin/toggleStatus' };

    /* roles and permission */
    public static get GETRESOURCESLIST(): string { return this.appurl + 'resources/getResourcesList' };
    public static get EDITEMPLOYEEPERMISSIONS(): string { return this.appurl + 'permissions/add' };

    /* import doc feature */
    public static get ASSETFOLDERSLIST(): string { return this.appurl + 'folder/listByFolders' };
    public static get ADDFOLDERCOMPANY(): string { return this.appurl + 'folder/addFolderToCompany' };
    public static get SELECTCOMPANYCATEGORY(): string { return this.appurl + 'select_category/docs' };


    public static get GETUSERSELECTEDRESOURCES(): string { return this.appurl + 'resources/getuserSelectedResouce' };
    public static get GETACCESSPERMISSIONS(): string { return this.appurl + 'permissions/getUserPermissions' };

    public static get GETARCHIVEDLIST(): string { return this.appurl + 'folder/archived/get' };

};
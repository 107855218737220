

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:9157/api/v1/', // api 
  // baseUrl: 'http://localhost:4200/', // front end
  // backendBaseUrl: 'http://localhost:9157/' //back end 
  
  // apiUrl: 'http://54.201.160.69:9157/api/v1/', // api
  // baseUrl: 'http://54.201.160.69:9157/', // front end
  // backendBaseUrl: 'http://54.201.160.69:9157/' // backend
  // apiUrl: 'http://35.81.128.12:9157/api/v1/', // api
  // baseUrl: 'http://35.81.128.12:9157/', // front end
  // backendBaseUrl: 'http://35.81.128.12:9157/' //back end
  apiUrl: 'https://hasp.app/api/v1/', // api 
  baseUrl: 'https://hasp.app/', // front end
  backendBaseUrl: 'https://hasp.app/' //back end 
};

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptorService } from './@core/interceptors/auth-interceptor.service';
import { ErrorInterceptorService } from './@core/interceptors/error-interceptor.service';
import { AuthGuard } from './@core/guards/auth-guard.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { WebStorage } from './@core/web.storage';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbLayoutModule,

  NbAlertModule,
  NbCardModule,
  NbInputModule,
  NbButtonModule,
  NbActionsModule,
  NbUserModule,
  NbCheckboxModule,
  NbRadioModule,
  NbSelectModule,
  NbIconModule,
  NbSpinnerModule,
  NbProgressBarModule
} from '@nebular/theme';
import { GeneralService } from './@core/services/sharedservices/generalservice/general.service';
import { CapitalizePipe } from './shared/pipes/capitalize.pipe';
import { PluralPipe, TimingPipe, NumberWithCommasPipe, RoundPipe } from './shared/pipes';
import { MatCheckboxModule } from '@angular/material';
import { MarketingPagesComponent } from './marketing-pages/marketing-pages.component';
 
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from "ngx-ui-loader";

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  text: "LOADING...",
  textColor: "rgba(206,209,216,0.67)",
  bgsColor: "rgba(12,80,219,0.98)",
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 80,
  bgsOpacity: 1,
  bgsType: SPINNER.rectangleBounce,
  fgsType: SPINNER.threeStrings, 
  fgsSize: 90,
blur:1,
};
let token: string = "";
export function jwtTokenGetter() {
  return token;
}
@NgModule({
  declarations: [AppComponent, CapitalizePipe, PluralPipe, TimingPipe, NumberWithCommasPipe, RoundPipe],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbWindowModule.forRoot(),
    NgxWebstorageModule.forRoot(),
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    NbLayoutModule,
    NbToastrModule.forRoot(),
    NbSpinnerModule,
    NbCardModule,
    NbUserModule,
    NbDialogModule.forRoot(),
    NbSelectModule,
    NbIconModule,
    MatCheckboxModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
   ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
    AuthGuard,
    WebStorage
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(public _webStorage: WebStorage) {
    token = this._webStorage.get('token');
  }
}

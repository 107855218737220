import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-marketing-pages-footer',
  templateUrl: './marketing-pages-footer.component.html',
  styleUrls: ['./marketing-pages-footer.component.scss']
})
export class MarketingPagesFooterComponent implements OnInit {

  constructor(
    private router: Router,

  ) { }

  ngOnInit() {
  }
  redirectToAbout(){
    this.router.navigate(['/about']);
  }
  redirectToFeature(){
    this.router.navigate(['/features']);
  }
  redirectToContact(){
    this.router.navigate(['/contact']);
  }
  redirectToPrivacyPolicy(){
    this.router.navigate(['/privacy-policy']);
  }
  redirectToTermsandCondition(){
    this.router.navigate(['/terms-and-conditions']);
  }
  redirectToLogin(){
    this.router.navigate(['/login']);
  }
  redirectToSignup(){
    this.router.navigate(['/sign-up']);
  }
}

import { HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Router } from '@angular/router';
import { generalConfig } from '../constants/general-config.constant';

export class ErrorInterceptorService implements HttpInterceptor {
    constructor(private _router: Router) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) => {

                if (error.error instanceof ErrorEvent) {

                    console.log(`Error: ${error.error.message}`);

                } else {

                    /* Forbidden */
                    if (error.status === 403) {
                        this._router.navigate([generalConfig.user.client + 'error/403']);
                        return throwError(error.error.message);
                    }
                    else if (error.status === 404) {
                        this._router.navigate([generalConfig.user.client + 'error/404']);
                        return throwError(error.error.message);
                    }

                }
            })
        )
    }
}
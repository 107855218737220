import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class AuthGuard implements CanActivate {


  constructor(private _authService: AuthService,
    private _router: Router,
    private tostar: ToastrService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._authService.isAuthenticated()) {
      return true;
    }
    else {
      
      this.tostar.error("Session Logout");
      this._router.navigate(['/auth/login']);
      // you can save redirect url so after authing we can move them back to the page they requested
      return false;
    }

  }

}
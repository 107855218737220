import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { WebStorage } from '../web.storage';

export class AuthInterceptorService implements HttpInterceptor {

    constructor(private _webStorage: WebStorage) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

        if (this._webStorage.get('token') == null) {
            return next.handle(req);
        } else {

            const modifiedRequest = req.clone({ headers: req.headers.append('Bearer', this._webStorage.get('token')) });

            return next.handle(modifiedRequest);
        }
    }
}
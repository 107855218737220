import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils/layout.service';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { MainService } from '../../../@core/utils/main.service';
import { environment } from '../../../../environments/environment';
import { generalConfig } from '../../../@core/constants/general-config.constant';
import { WebStorage } from '../../../@core/web.storage';
import { UserProfileService } from '../../../pages/user-profile/service/user-profile-service.service';
import { GeneralService } from '../../../@core/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  providers: [LayoutService, GeneralService]
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userImgUrl;
  userFnameUrl;
  baseimageurl: string = environment.backendBaseUrl;
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';



  url: any;
  userType: any = '';
  userMenu: any = '';
  userID: any = '';
  superAdminId: any = '';

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    private toastrService: NbToastrService,
    private mainService: MainService,
    private _storage: WebStorage,
    private userProfileService: UserProfileService,
    public generalService: GeneralService,
    private ngxService: NgxUiLoaderService

  ) { }

  ngOnInit() {
    this.getAdminDetail(this._storage.get(generalConfig.storage.ID));
    this.userID = this._storage.get(generalConfig.storage.ID);
    this.userType = this._storage.get(generalConfig.storage.USERTYPE);
    if (this._storage.get(generalConfig.storage.SUPERADMINID)) {
      this.superAdminId = this._storage.get(generalConfig.storage.SUPERADMINID);
    }

    if (this.userType == generalConfig.userType.SUPERADMIN) {
      this.userMenu = [{ title: 'Profile' }, { title: 'Change Password' }, { title: 'Log out' }];
    }else if(this.userType == generalConfig.userType.SUBADMIN){
      this.userMenu = [{ title: 'Log out' }];
    } else {
      if (this.superAdminId) {
        this.userMenu = [{ title: 'Profile' }, { title: 'Change Password' }, { title: 'Log out As Company' }];
      } else {
        this.userMenu = [{ title: 'Profile' }, { title: 'Change Password' }, { title: 'Log out' }];
      }

    }


    this.mainService.imgURLObservable.subscribe(
      (url) => {
        this.userImgUrl = url
      }
    )

    this.mainService.firstNameURLObservable.subscribe(
      (firstName) => { this.userFnameUrl = firstName }
    )

    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.superAdmin);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      ).subscribe(themeName => this.currentTheme = themeName);


    this.menuService.onItemClick().subscribe((event) => {

      // event.item.selected = true;
      this.onItemSelection(event.item.title);
    })
  }

  goBack() {
    this.generalService.goBack();
  }

  cancel() {
    this.generalService.cancel();
  }

  showToast(position, status, message) {
    this.toastrService.show(null, message, { position, status });
  }

  getAdminDetail(Id) {

    this.userProfileService.getAdminDetails({ id: Id }).subscribe((res: any) => {

      if (res && res.code == generalConfig.statusCode.ok && res.data) {
        this.mainService.changeProfileImage(res.data.image);
        const UserName = (res.data.firstName) + ' ' + (res.data.lastName);
        this.mainService.changeProfileFirstName(UserName);
        this.url = res.data.image;
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onItemSelection(title) {

    if (title === 'Log out') {
      this.ngxService.start(); 
      // localStorage.clear();
      this._storage.clearAll();
      this._storage.clear(generalConfig.storage.document);
      // this.toastrService.show(generalConfig.messages.Logout, 'success');

      setTimeout(() => { this.router.navigate(['/']) }, 500);
      // this.showToast(generalConfig.toastrPosition.topRight, generalConfig.toastrStatus.success, generalConfig.messages.Logout);
      this.ngxService.stop(); 

    }
    
    else if (title === 'Log out As Company') {
      // localStorage.clear();
      //this._storage.clearAll();
      //this._storage.clear(generalConfig.storage.document);
      // this.toastrService.show(generalConfig.messages.Logout, 'success');
      //setTimeout(() => { this.router.navigate(['/']) }, 500);
      this.ngxService.start(); 
      let data = {
        userID: this.superAdminId,
        //headerDialogue: true
      };
      //this.router.navigate([generalConfig.user.client + 'profile']);
      this.router.navigate(['/login/asSuperAdmin' + '/', this.superAdminId]);
      //this.generalService.resetPassword(data);
      this.ngxService.stop(); 

      // this.showToast(generalConfig.toastrPosition.topRight, generalConfig.toastrStatus.success, generalConfig.messages.Logout);
    }

    else if (title === 'Profile') {

      if (this.userType == generalConfig.userType.SUPERADMIN) {
        this.router.navigate([generalConfig.user.client + 'profile']);
      } else if (this.userType == generalConfig.userType.COMPANYADMIN) {
        this.router.navigate([generalConfig.user.client + 'profile/companyAdmin']);
      } else if (this.userType == generalConfig.userType.COMPANYSAFETYADMIN) {
        this.router.navigate([generalConfig.user.client + 'profile/companySafetyAdmin']);
      } else {
        this.router.navigate([generalConfig.user.client + 'profile/employees']);
      }

    }
    else if (title === 'Change Password') {

      let data = {
        userID: this.userID,
        headerDialogue: true
      };
      this.generalService.resetPassword(data);

      // if (this.userType == generalConfig.userType.SUPERADMIN) {

      // } else {
      //   let data = {
      //     userID: this.userID,
      //     headerDialogue: true
      //   };
      //   this.generalService.resetPassword(data);
      // }

    }

  }
}

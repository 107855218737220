import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NbToastrService } from '@nebular/theme';
import { ConfirmationDialogComponent } from '../../../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { ResetPasswordDialogComponent } from '../../../../shared/reset-password-dialog/reset-password-dialog.component';
import * as CryptoJS from 'crypto-js';

const passkey = "9@$$w0rdS32R#t";
@Injectable()
export class GeneralService {

  public tempUserDetails: any
  public loggedInUserDetails: any;
  public globalImage: any;
  public headerUserName: any;
  public dataForShare: any;

  constructor(
    public location: Location,
    private toastrService: NbToastrService,
    public dialog: MatDialog
  ) { }

  goBack() {
    this.location.back();
  }

  escapeHtmlCharacters(unsafe) {
    return unsafe
      .replace(/'\'/g, "")
      .replace("data:image/jpeg;base64,", "")
      .replace("data:image/png;base64,", "")

    // .replace(/&/g, "&amp;")
    // .replace(/</g, "&lt;")
    // .replace(/>/g, "&gt;")
    // .replace(/"/g, "&quot;")
    // .replace(/'/g, "&#039;");
  }

  setImageHeightWeight(height: number, width: number) {

    var maxWidth = 100;
    var maxHeight = 100;
    var ratio = 0;  // Used for aspect ratio

    // Check if the current width is larger than the max
    if (width > maxWidth) {
      ratio = maxWidth / width;   // get ratio for scaling image
      height = height * ratio;    // Reset height to match scaled image
      width = width * ratio;    // Reset width to match scaled image
    }

    // Check if current height is larger than max
    if (height > maxHeight) {
      ratio = maxHeight / height; // get ratio for scaling image
      width = width * ratio;    // Reset width to match scaled image
      height = height * ratio;    // Reset height to match scaled image
    }

    return { width, height };
  }

  showToast(position, status, message) {
    this.toastrService.show(null, message, { position, status });
  }

  cancel() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Are you sure? You want to cancel this operation?"
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.goBack();
      }
    });
  }

  cancelDialog(referenceDialog) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: "Are you sure? You want to cancel this operation?"
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        referenceDialog.close();
      }
    });
  }
  resetPassword(companyData) {
    const dialogRef = this.dialog.open(ResetPasswordDialogComponent, {
      width: '350px',
      data: companyData
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this.goBack();
      }
    });
  }

  /* encrypt text */
  encryptText(textToConvert) {
    return CryptoJS.AES.encrypt(textToConvert.trim(), passkey.trim()).toString();
  }

  /* descrypt text */
  decryptText(textToConvert) {
    return CryptoJS.AES.decrypt(textToConvert.trim(), passkey.trim()).toString(CryptoJS.enc.Utf8);
  }
}